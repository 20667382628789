:root {
    --text-color: #333;
    --text-second-color: #666;
    --text-third-color: #999;
    --border-color: #e8e8e8e8;
    --block-color: #fff;
    /* // bg color */
    --body-bg-color: #f3f3f3;
}

$block-bg: #FFFFFF;
$radius: 4px;

// color
$border-color: #e8e8e8;
// border
$border: 1px solid $border-color;
$fontWeight-400: 400;
$fontWeight-500: 500;
$fontWeight-600: 600;

$paddding-4: 4px;
$paddding-8: 8px;
$paddding-12: 12px;
$paddding-16: 16px;
$paddding-23: 32px;

@mixin page($padding: 24) {
    background: $block-bg;
    padding: $padding;
}

@mixin blank_page {
    background: $block-bg;
    padding: 16px;
    border-radius: $radius;
}

@mixin font-size-default($size: 14) {
    $lineHeight: $size + 4;
    font-size: $size + px;
    line-height: $lineHeight + px;
}

@mixin multi-line ($num) {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: $num;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin single-line {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    -moz-text-overflow: ellipsis;
    white-space: nowrap;
}

// 
$somo-prefix: "somo";
.masterForm {
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  // padding: 8px;
  // overflow: hidden;

  &-body {
    flex: 1;
    // overflow: auto;
  }

  &-footer {
    position: sticky;
    bottom: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    background: #fff;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;

    .actions {
      display: flex;

      button {
        margin-left: 12px;
      }
    }
  }
}